<template>
      <footer class="footer-alignment">
        <div class="container container-fluid d-none d-sm-none d-md-none d-lg-block">
          <!-- <div class="row footer-nav-link ">
            <div class="col pl-0 text-left"><a class="nav-link pl-0" href="/aboutus">About Us</a></div>
            <div class="col text-center"><a class="nav-link" href="#">Patients-like-you groups</a></div>
            <div class="col pr-0 text-right"><a class="nav-link pr-0" href="/contactus">Contact Us</a></div>
          </div> -->
        </div>
        <!-- <div class="container container-fluid ">
          <div class="row footer-text">
              <div class="col-lg-5  pl-0"><a class="nav-link p-0" href="https://www.bambeeq.com/">BambeeQ Solutions Pvt Ltd</a></div>
               <div class="col-lg-7 col-sm-3">All rights reserved 2021</div>
          </div>
        </div> -->
      </footer> 
</template>

<script>
import{ defineComponent } from 'vue'
export default defineComponent({
    name: "Footer",
    props:{

    }
   
})
</script>

<style>
.footer-alignment{
  margin:30px;
}
.container, .container-fluid{
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 1200px){
.container-sm, .container {
    max-width: 1140px;
 }
}
.footer-nav-link {
  padding: 7px;
  background: #00979e;
  color: #ffffff;
}
.row.footer-nav-link a {
  color: #fff;
}
.footer-text {
  background: #ff8000;
  color: #fff;
  padding: 5px;
}
/* .footer-text a {
  color: #ffffff;
} */
p.inner-box {
  padding: 10px;
  width: 133px;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #fff;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.pl-0, .px-0 {
    padding-left: 0!important;
    padding-right: 0!important;
}


#team {
    background: #fff;
    padding: 60px 0;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1)
}

.section-header h3 {
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative
}

.section-header p {
    text-align: center;
    margin: auto;
    font-size: 15px;
    padding-bottom: 60px;
    color: #556877;
    width: 50%
}

.fadeInUp {
    animation-name: fadeInUp
}

#team .member {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    border-radius: 50%;
    overflow: hidden
}

#team .member .member-info {
    opacity: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s
}

#team .member .member-info-content {
    margin-top: 50px;
    transition: margin 0.2s
}

#team .member:hover .member-info {
    background: rgba(0, 62, 128, 0.7);
    opacity: 1;
    transition: 0.4s
}

#team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff
}

#team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff
}

#team .member .social a {
    transition: none;
    color: #fff
}

.sptr {
  border-bottom: 1px solid #cccccc;
}

.carousel-multi-item .carousel-inner.v-2 .carousel-item.active, .carousel-multi-item .carousel-inner.v-2 .carousel-item-next, .carousel-multi-item .carousel-inner.v-2 .carousel-item-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
