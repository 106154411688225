<template>
  <div class="container-fluid home-page-B-containter rx-wrapper pl-0 pr-0" style="background: #ffffff">
    <nav class="navbar navbar-expand-lg navbar-light" style="background: #ffffff">
      <div class="col-2 navbar-brand" @click="landingRoute" style="margin-top:-22px; cursor: pointer;">
        <img src="@/assets/rxix-logo.png" class="img-fluid" alt="RxIx" />
      </div>
      <button class="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navigations-02">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-md-center" id="navigations-02">
        <div class="row w-100">
          <div class="col-11 pr-lg-0">

            <ul class="navbar-nav justify-content-end rxix-menu">
              <li class="col col-lg-2 nav-item nav" @click="publicRoute" style="cursor: pointer;">
                    <h4 data-toggle="modal" data-target="#publicModal" class="nav-link rxix-menu"
                        style="color: #000; font-size: 24px;" :class="{ 'CurrentPageActive': isRouteActive('/public') }">Public
                      </h4>
              </li>
              <li class="col col-lg-2 nav-item nav" @click="doctorRoute" style="cursor: pointer;">
                <h4  class="nav-link cursor-pointer" style="color: #000; font-size: 24px;" :class="{ 'CurrentPageActive': isRouteActive('/doctor') }">Doctors
                </h4>
              </li>
              <li class="col col-lg-2 nav-item nav" @click="aboutusRoute" style="cursor: pointer;">
                <h4 class="nav-link cursor-pointer" style="color: #000; font-size: 24px;"  :class="{ 'CurrentPageActive': isRouteActive('/aboutus') }">About Us
                 </h4>
                </li>
              <li class="nav-item">
                <button class="btn btn-blue-color text-white sign1" @click="cardSign()">Sign In</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script >
import { defineComponent } from "vue";
import "../../css/app.css";
export default defineComponent({
  components: {
  },
  name: "Header",
  props: {},
  data() {
    return {
      emailId: "",
      password: "",
      isLoginState: "",
      currentPath: "",
      // issubmit:false
    };
  },
  methods: {
    landingRoute(){
      window.scrollTo(0, 0);
      this.$router.push('/');
    },
    publicRoute(){
      window.scrollTo(0, 0);
      this.$router.push('/public');
    },
    doctorRoute(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor');
    },
    aboutusRoute(){
      window.scrollTo(0, 0);
      this.$router.push('/aboutus');
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },
    cardSign() {
      window.scrollTo(0, 0);
      this.$router.push('/signup');
    }
  },
  created: function () {
    this.currentPath = this.$route.path.split('/')[2]
  },
});
</script>
<style scoped>
.CurrentPageActive{
  border-bottom: 2px solid #34989f;
  width: fit-content;
}
.container-fluid.home-page-B-containter.rx-wrapper.pl-0.pr-0 {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar-nav.justify-content-end.rxix-menu {
  padding-right: 12px;
  justify-content: flex-end !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  padding-left: 22px;
  float: right;
  margin-right: 10px;
}

li.nav-item.nav {
  padding-right: 20px;
}

button.btn.btn-blue-color.text-white {
  width: 100%;
  font-size: large;
  font-weight: bold;
}
</style>